import React from 'react';
import './fields.css'
import { Typography } from '@material-ui/core';

import { default as logo } from './errorSvg.svg';

const ErrorMessage = ({error,item}) => {

    return(
        <Typography variant="caption" color="error">
            {error &&
            ((error.type === "required" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>{item.name} is required</p>)
            || ((error.type === "min" || error.type === "max")  && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>Wrong the Weight field. Subject must be between 50 and 500 pounds</p>)      
            || (error.type === "minLength" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>{item.name} is too short. MinLength is: {item.minLength}.</p>)
            || (error.type === "maxLength" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>{item.name} is too long. MaxLength is: {item.maxLength}.</p>)
            || (error.type === "pattern" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>{getErrorMessagePattern(item)}</p>)
            || (error.type === "isDateFormat" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>Invalid date format</p>)
            || (error.type === "validate" && 
            <p className="fieldError"><img className='errorImage' alt='' src={logo}/>Wrong the Height field. Subject must be between 4'00" and 7'11".</p>)
            )
            }
        </Typography>
    )       
}



const getErrorMessagePattern = (field) => {
    if(field.characterType=="alpha" && !field.specialCharacters)  return "Only letters are allowed.";
    if(field.characterType=="numeric" && !field.specialCharacters)  return "Only digits are allowed.";
    if(field.characterType=="alphaNumeric" && !field.specialCharacters)  return "Only letters and digits are allowed.";

    if(field.characterType=="alpha" && field.specialCharacters)  return `Only letters and the following special characters are allowed: ${getSpecialCharacters(field.specialCharacters)}.`;
    if(field.characterType=="numeric" && field.specialCharacters)  return `Only digits and the following special characters are allowed: ${getSpecialCharacters(field.specialCharacters)}.`;
    if(field.characterType=="alphaNumeric" && field.specialCharacters)  return `Only letters, digits and the following special characters are allowed: ${getSpecialCharacters(field.specialCharacters)}.`;

    return "";
} 

const getSpecialCharacters = (specialCharacters) => {
    let string = [];
    specialCharacters.split('').forEach(s => {
        string.push("\"" + (s==' ' ? 'space' : s) + "\"");
    })
    return string.join(',');
}

export default ErrorMessage;
