import { combineReducers } from "redux";
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import recordReducer from './record/recordReducer'
import requestReducer from "./requests/requestReducer";

const rootReducer = combineReducers({
    record: recordReducer,
    resultRequest: requestReducer
})

export const store = createStore(rootReducer,applyMiddleware(thunk))
