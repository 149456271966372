import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import requestActions from './store/requests/requestActions';
import './pages/styles.css';
import Booking from './pages/bookingPage/Booking';
import DemographicForm from './pages/demographicFormPage/DemographicForm';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import offices from './resources/Offices.json';
import filterById from './resources/jsonFuncions';
import { createBrowserHistory } from 'history'
import NotFound from './NotFound';
import OperatorStory from './pages/operatorStory/OperatorStory'

export default function App() {

    const history = createBrowserHistory();  
    const dispatch = useDispatch();
    let agency = new URLSearchParams(useLocation().search).get("agency") || "LSITBIO00";
    
    var office = {};
    // = filterById(offices,'ori',agency);
    

    // if(!office){
    //     history.push('/404')
    // }

    return (
        <Router history={history}> 
            <Switch>
                <Route exact path="/" render={() => <Booking agency={agency}></Booking>}  />   
                <Route path={"/demo"} component={DemographicForm} />
                <Route path={"/appointments"} component={OperatorStory} />
                <Route component={NotFound} />
            </Switch>
        </Router>)
}