import React from 'react';
import './fields.css'
import { Controller } from 'react-hook-form';
import TextBoxField from './TextBoxField';
import InputMaskField from './InputMaskField';
import ListBoxField from './ListBoxField';
import WeightField from './WeightField'
import DatePickerField from './DatePickerField'
import ErrorMessage from './ErrorMessage'

const Field = ({item,handleChange,control,errors,office}) => {
    let rules = getRules(item);
    
    if(item.type == 'height'){
        rules = {...rules, ...{validate: isHeight}};
       
    }
    
    if(item.type == 'date') 
        return(
            <div className='field'>
                <label className='fieldName'>{item.name} {!rules.required && "- Optional"}</label>
                <DatePickerField mnemonic={item.mnemonic} handleChange={handleChange} rules={rules} control={control}/>
                <ErrorMessage error={errors[item.mnemonic]} item={item} />
            </div>
        )

    return(
        <div className='field'>
            <label className='fieldName'>{item.name} {!rules.required && "- Optional"}</label>
    
            <Controller
                name={item.mnemonic}
                control={control}
                errors={errors[item.mnemonic]}
                rules={rules}
                render={({ field }) => { 
                    if(item.type == 'text')    
                        return (
                        <TextBoxField
                        field = {field} 
                        mnemonic={item.mnemonic}
                        handleChange={handleChange}
                        rules={rules}/>)

                    if(item.type == 'listBox') 
                        return(
                            <ListBoxField
                            field = {field} 
                            handleChange={handleChange}
                            item={item}/>)

                    if(item.type == 'height')
                        return(
                            <InputMaskField
                            field={field}
                            handleChange={handleChange}
                            mnemonic={item.mnemonic}
                            mask="F’II’’"
                            formatChars={{'F':'[4567]', 'I':'[0-9]'}}/>)
                    
                    if(item.type == 'weight')    
                        return (
                        <WeightField
                        field = {field} 
                        mnemonic={item.mnemonic}
                        handleChange={handleChange}/>)
    
                    if(item.type == 'ssn')
                        return (
                    <div style={{'display':'contents'}}>
                        <InputMaskField
                            field={field}
                            handleChange={handleChange}
                            mnemonic={item.mnemonic}
                            mask="999-99-9999"
                            formatChars={{'9': '[0-9]'}}/>
                    {
                        !rules.required &&
                        <label className="fieldDetails">It is not a required field, however it may be required at the time of enrollment</label>            
                    }
                    </div>)
                            
            }}
            />
            <ErrorMessage error={errors[item.mnemonic]} item={item} />
        </div>
    )    
}



const getRules = (item,additionalRules = null) => {
    const rules = {
        maxLength: Number(item.maxLength) || null,
        minLength: Number(item.minLength) || null,
        required: item.minOccurs == true || additionalRules,
        pattern: getPattern(item.characterType ,item.specialCharacters)   
    }

    Object.keys(rules).forEach(key => {
        if (rules[key] == null) {
          delete rules[key];
        }
      });

    return rules;
}

const getPattern = (characterType, specialCharacters) => {
    if(characterType=='numeric') return /^[0-9]*$/;

    if(characterType=='alpha' && !specialCharacters) return /^[a-zA-Z]*$/;
   
    if(specialCharacters){
        const alpha = "a-zA-Z";
        const chars = "\\" + specialCharacters.split('').join('\\');
        const exp = "^[" + alpha + chars + "]*$";
        return new RegExp(exp)
    }
    
    return null;
}

const isHeight=(hgt) => {
    const inches =hgt.replaceAll('’','').slice(1,3)
    return inches>=0 && inches<=11
}   


export default Field;
