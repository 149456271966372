import React from 'react';
import './fields.css'
import useStyles from './useStyle';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePickerField = ({mnemonic,handleChange,rules,control}) => {

    const classes = useStyles();
    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
            name={mnemonic}
            control={control}
            onChange={(e)=> handleChange(mnemonic,e)}
            rules={{...{isDateFormat:isDate},...rules}}
            render={({ field: { ref, ...rest }  }) => (
            <KeyboardDatePicker
                {...rest}
                classes={classes}
                className="field-box" 
                format="MM/dd/yyyy"
                placeholder='MM/DD/YYYY'
                openTo="year"
                views={["year", "month", "date"]}
                helperText=''
                onChange={(e)=> handleChange(mnemonic,e)}
                InputProps={{disableUnderline: true}}
                KeyboardButtonProps={{'aria-label': 'change date'}} 
            />
            )}
        />
        </MuiPickersUtilsProvider>
    )
}

const isDate=(date) => {
    return date.toString()!=='Invalid Date'
}

export default DatePickerField;
