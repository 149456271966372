import React,{useState, useEffect} from 'react';
import { ScheduleComponent, Day, Week, WorkWeek, Inject,
    ViewsDirective, ViewDirective
  } from '@syncfusion/ej2-react-schedule';
import './OperatorStoryStyles.css'
import {useDispatch, useSelector} from 'react-redux';
import requestActions from '../../store/requests/requestActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";

function addMinutes(time, minsToAdd) {
    function D(J){ return (J<10? '0':'') + J};
    var piece = time.split(':');
    var mins = piece[0]*60 + +piece[1] + +minsToAdd;
    return D(mins%(24*60)/60 | 0) + ':' + D(mins%60);  
}  

const dateSchedule = (date) => {
    const plusOne= date[0]==='B' ? 1 : 0;
    var year= parseInt(date.substring(plusOne,4+plusOne),10);
    var month= parseInt(date.substring(4+plusOne,6+plusOne),10);
    var day= parseInt(date.substring(6+plusOne,8+plusOne),10);
    return new Date(year,month-1,day)
}



function convertScheduleFormat(item){
  var arrayOfStrings = item.split(' ');
  const date=dateSchedule(arrayOfStrings[0])
  var time=arrayOfStrings[1].split(':');
  var hourStart = parseInt(time[0], 10);
  var minStart = parseInt(time[1], 10);
  var endTime=addMinutes(arrayOfStrings[1],'15');
  endTime=endTime.split(':');
  var hourEnd = parseInt(endTime[0], 10);
  var minEnd = parseInt(endTime[1], 10);
  
  return{
    Subject:" ",
    StartTime: new Date(date.setHours(hourStart,minStart)),
    EndTime: new Date(date.setHours(hourEnd,minEnd))
  }
}

function GetDateFromSchedule(dateTime,number){
    return dateTime.at(number).split(' ')[0];
  }

export default function OperatorStory(){

    const [schedule, setSchedule] = useState();
    const scheduleError = useSelector(state => state.resultRequest.schedule.error)
    const dispatch = useDispatch()
    const [lastDate,setLastDate]=useState(new Date);
    const {search}=useLocation();
    const agency = new URLSearchParams(search).get('agency');
    const [open, setOpen] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        dispatch(requestActions.getSchedule(agency===null ? "LSITBIO00" : agency,(res)=> {
            res=res.filter(e => e[0]=== 'B')
            setSchedule(res.map(item =>{
              return convertScheduleFormat(item)
              }))
            setLastDate(dateSchedule(GetDateFromSchedule(res,-1)))
            setOpen(false)
        }));
      }, []);

    useEffect(()=>{
      if (scheduleError!==null)
        setOpen(false)
    }, [scheduleError])

    return (
      <div className="booking">
        <div className='header'>
          <h3 style={{fontWeight: '400'}}>LIVESCAN APPOINTMENT</h3>
        </div>
        <div className="scheduleHeader">SCHEDULE APPOINTMENT</div>
                          
        <ScheduleComponent width='100%' currentView='WorkWeek' readonly={true}
            eventSettings={{ dataSource: schedule}}
            startHour='07:00' endHour='20:00' hover={onHover}
            minDate={new Date(new Date().setHours(0,0,0))} 
             maxDate={lastDate}>
          <ViewsDirective>
            <ViewDirective option='Day' />
            <ViewDirective option='Week' />
            <ViewDirective option='WorkWeek' />
          </ViewsDirective>
          <Inject services={[Day, Week, WorkWeek]} />
        </ScheduleComponent>

        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop> 
      </div>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function onHover(args) {
  if (args.element.classList.contains('e-work-cells')) {
    let workCells = document.querySelectorAll(".e-work-cells");
    [].forEach.call(workCells, function (ele) {
      ele.classList.remove("custom");
    });
    args.element.classList.add('custom');
  }
}