import React from 'react';

const TypeTransactionInfo = props => {
    switch(props.selectedTOT){
      case 'FAP': {
        return(
          <div className="transaction-info"> 
          <p className="transaction-info-usedfor">Used for:</p>
          <li className="transaction-info-item">Licensed Practical Nurse</li>
          <li className="transaction-info-item">Registered Practical Nurse</li>
          <li className="transaction-info-item">Medical Marijuana</li>
          <li className="transaction-info-item">Physician</li>
          <li className="transaction-info-item">Conceal And Carry</li>
          <li className="transaction-info-item">Liquor License</li>
          <li className="transaction-info-item">Public and Private Schools</li>
          <li className="transaction-info-item">IDPH</li>
          <li className="transaction-info-item">PERC</li>
          <li className="transaction-info-item">Remitter/Vehicle Dealer License</li>
          <li className="transaction-info-item">Out of State Card Scanning</li>
          </div>
        )
      }
      case 'UFP': {
        return(
        <div className="transaction-info col-md-5 col-lg-6 offset-lg-1 "> 
        <p className="transaction-info-usedfor">Used for:</p>
        <li className="transaction-info-item">Personal State BackGround Check for Work or Illinois Police</li>
        <li className="transaction-info-item">Chauffer License</li>
        <li className="transaction-info-item">Solicitors</li>
        <li className="transaction-info-item">Out of State Card Scanning</li>
        </div>
        )
      }
      case 'RAR': {
        return( 
        <div className="transaction-info col-md-5 col-lg-6 offset-lg-1 "> 
        <p className="transaction-info-usedfor">Used for:</p>
        <li className="transaction-info-item">Criminal Background Rap Sheet from FBI and State</li>
        <li className="transaction-info-item">Record Challenge</li>
        </div>
        )
      }
      case 'APP': {
        return(
        <div className="transaction-info col-md-5 col-lg-6 offset-lg-1 "> 
        <p className="transaction-info-usedfor">Used for:</p>
        <p className="transaction-info-item">The Criminal Justice Applicant fingerprint card is be used by criminal justice agencies when an individual is applying to work at a criminal justice facility.</p>
        </div>
        )
      }
      default:
        return(<div/>)
    }
    
} 

export default TypeTransactionInfo