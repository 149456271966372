import React, { useState,useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import './DemographicFormStyles.css';
import ProgressSteps from './progressSteps/ProgressSteps';
import DemographicData from './steps/demographicDataStep/DemographicData';
import Submit from './steps/submitStep/Submit';
import Final from '../finalPage/Final';
import TypesTransactions from './steps/typeTransactionStep/TypesTransactions';
import { useHistory,useLocation } from "react-router-dom";
import BookingCalendar from './steps/bookingCalendarStep/BookingCalendar'
import DataSources from '../../resources/DataSources.json';
import { SwitchTransition, CSSTransition } from "react-transition-group";


export default function DemogragraphicForm () {
    
    const [step, setstep]=useState(1);
    let history = useHistory();
    const { search } = useLocation();

    const dataSources=DataSources.dataSources;

    let office = useSelector(state => {
        const { resultRequest } = state;
        //return {...resultRequest.agency.agency, HasScheduler: false}
        return resultRequest.agency.agency;
    });


    if(step>4 || (!office?.HasScheduler && step>3))
        return <Final/>
        
    return ( 
        <div className="booking">
            
        <div className='header' onClick={() => history.push({pathname: "/", search: search})}>LIVESCAN APPOINTMENT</div>
        <ProgressSteps currentStep={step}/>
        <SwitchTransition>
        <CSSTransition
            key={step}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="next">

            {step===1  ?
                <TypesTransactions tot={dataSources.DS_TOT} functions={[step,setstep]}/>
            :
            step===2 ?  
                <DemographicData dataSources={dataSources} functions={[step,setstep]}/>
            :
            !office?.HasScheduler ? 
                <Submit dataSources={dataSources} functions={[step,setstep]}/>
            :
            step===3 && office?.HasScheduler ?  
                <BookingCalendar functions={[step,setstep]}/>
            :
                <Submit dataSources={dataSources} functions={[step,setstep]}/>
            }
          </CSSTransition>
        </SwitchTransition>
        </div>
    )
}
