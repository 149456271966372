import React from 'react';
import './fields.css'
import TextField from '@material-ui/core/TextField';
import useStyles from './useStyle';

const WeightField = ({field,handleChange,mnemonic}) => {

    const classes = useStyles();
    return(
        <TextField                
        {...field}
        classes={classes}
        type="number"
        placeholder='lbs'
        className="field-box" 
        InputProps={{ 
            disableUnderline: true,
            inputProps: { min: 50, max: 500 } }}
        onChange={(e) => {handleChange(mnemonic,e.target.value.slice(0,3))}}
        />
    )    
}

export default WeightField;
