import React from 'react';
import './fields.css'
import TextField from '@material-ui/core/TextField';
import useStyles from './useStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DataSources from '../../../../../resources/DataSources.json'

const ListBoxField = ({field,handleChange,item}) => {
    const classes = useStyles();
    return(
        <Autocomplete
            {...field}
            groupBy={(option) => (item?.dataSource=='DS_POB' ? (option?.Geo || 'Countries') : '')}
            options={getDataSourceByMnemonic(item?.dataSource)}
            classes={classes}
            getOptionLabel={(option) => option.Label || ""}
            onChange={(e,newValue)=> {handleChange(item.mnemonic,newValue)}}
            renderInput={(params) => <TextField {...params} 
            InputProps={{...params.InputProps, disableUnderline: true}}/>}
        />
    )    
}

const getDataSourceByMnemonic = (mnemonic) => {
    return DataSources.dataSources[mnemonic]  || []
}


export default ListBoxField;
