
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        "& .jss3":{
            top: "0px",
            height:'37px',  
        },
        "& .notchedOutline": {
          top: "0px",
          borderColor:'red',
          height:'48px',
        },
        background: '#fff',
        height:'37px',
        borderRadius:'5px',
        textTransform:'uppercase',
        "& .MuiOutlinedInput-notchedOutline":{
            border: '1px solid #D9D9D9',
        },
        "& .MuiInputBase-root":{
            padding:'0.375rem 0.75rem',
            textTransform:'uppercase',
        },
        "& .MuiFormHelperText-root": {
            color: '#FF4A4A',
            fontSize: '13px',                
            marginTop: '-5px',
            paddingLeft: '5px',
        },
        "& .MuiAutocomplete-input":{
            textTransform:'uppercase',
        }
    }
});

export default useStyles;