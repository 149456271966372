import React, { useState, useEffect,  } from 'react';

import { useDispatch, useSelector } from "react-redux";
import './BookingStyles.css';
import { BrowserRouter as Router, Switch, Route, useLocation,useHistory } from "react-router-dom";
import Modal from "./Modal";
import recordActions from '../../store/record/recordActions';

import requestActions from '../../store/requests/requestActions';

import {CountdownTimer} from '../../components/CountdownTimer';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import './OfficeCardStyles.css';

export default function Booking (props) {

    const dispatch = useDispatch();
    let history = useHistory();

    const classes = useStyles();

    const [modalActive, setModalActive]=useState(false);
    const [loadPage, setLoadPage]=useState(true);


    useEffect(() => {
        dispatch(recordActions.resetStore());
    },[])

    useEffect(() => {
        dispatch(requestActions.getAgencyData(props.agency,(res) =>{
            if(!res) history.push('/404')
            setLoadPage(false);
        }))
    },[]);

    let office = useSelector(state => {
        const { resultRequest } = state;
        //console.log(resultRequest.agency.agency)
        return resultRequest.agency.agency;
    });

    return (
    <div>
        <div hidden={loadPage} className="booking">
            <div className='header'>
                <button className='btn-back' onClick={history.goBack}>
                    {/* <span> */}
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2L3 9.5L12 17" stroke="white" strokeWidth="2"/>
                    </svg>
                    {/* </span>  */}
                    <span className='btn-text'>Go back</span>
                </button>
            {/* LIVESCAN APPOINTMENT */}
                <h3 style={{fontWeight: '400'}}>LIVESCAN APPOINTMENT</h3>
            </div>

            <main className="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
                <div className="contentBooking">
                    <OfficeCard office={office}/>
                    <InfoBooking setModalActive={setModalActive}/>
                </div>
            </main>
            <Modal active={modalActive} setActive={setModalActive}/>

        </div>
        <Backdrop className={classes.backdrop} open={loadPage}>
                <CircularProgress color="inherit" />
        </Backdrop> 
    </div>
    );
}

function InfoBooking(props){
    return(
        <div className="info-booking">
            <div className="compl">
                <p>SAVE TIME!</p>
                <p>CLICK BELOW TO SCHEDULE AN APPOINTMENT.</p>
            </div>
            <button className="button-booking" onClick={()=>props.setModalActive(true)}>Appointment</button>
        </div>
    )
}

function OfficeCard (props) {
    return (
        <div className="info-office">
            <li hidden={!props.office?.Logo && !props.office?.logo} className="logo-office"><img src={props.office?.Logo || props.office?.logo} className="qwer" alt="" /></li>
            <div className="dd">
                <li hidden={!props.office?.Location} className="info">
                    <i className="logo1 bi bi-geo-alt-fill"></i>
                    <p>
                    {props.office?.Location},
                    <br/> 
                    {props.office?.Location2}
                    </p>
                </li>
            
                <li hidden={!props.office?.Phone} className="info">
                <i className="logo1 bi bi-telephone-fill"></i>
                {props.office?.Phone}
                </li>
                
                
                <li hidden={!props.office?.Email} className="info">
                <i className="logo1 bi-envelope-fill"></i>
                <a href={`mailto:${props.office?.Email}`}>{props.office?.Email}</a>
                </li>

                <li hidden={!props.office?.Website} className="info">
                <i className="logo1 bi bi-globe"></i>
                <a href={props.office?.Website}>{props.office?.Website}</a>
                </li>
            
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
    background: '#fff',
      zIndex: theme.zIndex.drawer + 1,
      color: '#27689E',
    },
  }));