import React, {useState, useEffect} from 'react';
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import { Calendar, utils } from "react-modern-calendar-datepicker"
import './BookingCalendarStyles.css'
import {useDispatch, useSelector} from 'react-redux';
import recordActions from '../../../../store/record/recordActions';
import requestActions from '../../../../store/requests/requestActions';
import useWindowSize from './useWindowSize';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";


export default function BookingCalendar(props){

  const [isHidden, setIsHidden] = useState(true);
  const dispatch = useDispatch()
  const record = useSelector(state => state.record);
  const scheduleError = useSelector(state => state.resultRequest.schedule.error)
  const [step, setstep] = props.functions;
  const [schedule, setSchedule] = useState(null);


  const {search}=useLocation();
  const agency = new URLSearchParams(search).get('agency');

  const [open, setOpen] = useState(true);

  const classes = useStyles();

  const [times,setTimes]=useState(null)
  
  var date = new Date();
  const [firstDay,setFirstDay] = useState({year: date.getFullYear(), month:date.getMonth()+1, day:1});
  const [lastDay, setLastDay] = useState({year:date.getFullYear(), month:date.getMonth()+1, day:0});
  const [disabledDays,setDisabledDays]=useState([]);
  const myDateTime = record.SCHEDULE

  const [selectedDayTime, setSelectedDayTime] = useState({date:utils().getToday(), time: null});
  const size = useWindowSize();
  

  let office = useSelector(state => {
    const { resultRequest } = state;
    return resultRequest.agency.agency;
  });

  useEffect(() => {
    dispatch(requestActions.getSchedule(agency===null ? "LSITBIO00" : agency,(res)=>{
      setSchedule(res.filter(e => e[0]!== 'B').filter(e=>compareDateTime(e)).map(item => {
      return convertScheduleFormat(item)
      }))
      setOpen(false)
    }));
    setSelectedDayTime(myDateTime!==null ? myDateTime : {date: utils().getToday(), time: null})
  }, []);
      
  useEffect(() => {
    if(!isHidden)
      setIsHidden(selectedDayTime.time!=null)
  }, [selectedDayTime]);

  
  useEffect(()=>{
    if(schedule===null || schedule.length===0) return
    setFirstDay(stringToDate(GetDateFromSchedule(schedule,0)))
    setLastDay(stringToDate(GetDateFromSchedule(schedule,-1)))
    setDisabledDays(getDisabledDate(schedule))
    
    let numbers = schedule.filter(function(e) {
      var date = `${selectedDayTime.date.year}-${('0'+selectedDayTime.date.month).slice(-2)}-${('0'+selectedDayTime.date.day).slice(-2)}`
      return e.indexOf(date)!==-1 })
    
    numbers = numbers.map(i => {return i.substr(i.indexOf(" ") + 1)})

    let subarray = []; 
    const j = size.width>=1600 ? 8
      : size.width>=1400 ? 7 
      : size.width>=1260 ? 6 
      : size.width>=1024 ? 5 
      : size.width>=426 ? 4 : 3 
    for (let i = 0; i <Math.ceil(numbers.length/j); i++){
      subarray[i] = numbers.slice((i*j), (i*j) + j);
    }
    const listItems = <table className='tableTimes'>{subarray.map((numberRow) =>
      <tr>
        {
          numberRow.map(number =>
        <td> <button className={ number===selectedDayTime.time ? "buttonsTime selected" : 'buttonsTime'} value={selectedDayTime} onClick={() => setSelectedDayTime({date: selectedDayTime.date, time:number})}>
        {number} </button>
        </td>)
        }
      </tr>)}</table> 
    setTimes(listItems)
  },[selectedDayTime,schedule,size])

  useEffect(()=>{
    if (scheduleError!==null){
      setOpen(false)
    } 
  }, scheduleError)


 
return (
      <div>
        <div className="contant">
        <CalendarSchedule functions={[selectedDayTime,setSelectedDayTime]} schedule={schedule} firstDay={firstDay} lastDay={lastDay} disabledDays={disabledDays}/>
        <div>
          <div className="error-dateTime" hidden={isHidden}>choose date and time, please </div>
          <div className="available">
            Select an appointment date and time from the list of available timeslots.
          </div>
          <div className='times'>{times}</div>
        </div>
      </div>
    <div className='buttons'>
      <button className="button button-next" onClick={()=>setstep(step-1)}>Prev</button>
      {!office?.NoSkipScheduler ? <button className="button button-next" onClick={()=>{dispatch(recordActions.changeSCHEDULE(null)); setstep(step+1)}}>Skip</button> : null} 
      <button className="button button-next" disabled={schedule===null} onClick={()=>{
        if(selectedDayTime.time===null) setIsHidden(false)
        else{
          dispatch(recordActions.changeSCHEDULE({date: selectedDayTime.date, time: selectedDayTime.time}));
          setstep(step+1)} 
        }}>Next</button>
    </div>
    
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop> 
    </div>
  );
}


const CalendarSchedule = props =>{
  const [selectedDayTime,setSelectedDayTime]=props.functions;

  return(
  <Calendar
    minimumDate={props.firstDay}
    maximumDate={props.lastDay}
      value={selectedDayTime.date}
      colorPrimary="#154e7d"
      onChange={e => setSelectedDayTime({date: e, time:null})}
      calendarClassName="custom-calendar"
      calendarTodayClassName="custom-today-day"
      shouldHighlightWeekends
      disabledDays={props.disabledDays}
      // disabledDays={getDisabledDate(props.schedule)}
    />)
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function stringToDate(stringDate){
  var date = stringDate.split('-')
  return({
    year: parseInt(date[0],10),
    month: parseInt(date[1],10),
    day: parseInt(date[2],10)
  })
}

var getDates = function(startDate, endDate) {
  var dates = [],
  currentDate = startDate,
  addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate,1);
  }
  return dates;
};

function GetDateFromSchedule(dateTime,number){
  return dateTime.at(number).split(' ')[0];
}

function GetArrayOfDatesBetween2Dates(schedule){
  var fromDate = GetDateFromSchedule(schedule,0)
  var toDate = GetDateFromSchedule(schedule,-1)
  var dates = getDates(new Date(fromDate), new Date(toDate) );
  return dates.map(date=>{
    return `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}`
  })
}

function getDatesFromSchedule(schedule){
  var arrayDate = schedule.map( day => {return day.split(' ')[0]})
  return arrayDate.filter(function(item, pos) {
    return arrayDate.indexOf(item) == pos;
})  

}


function getDisabledDate(schedule){
  var arrayOfDatesBetween2Dates = GetArrayOfDatesBetween2Dates(schedule)
  var datesFromSchedule=getDatesFromSchedule(schedule)  
  
  var disabledDate = arrayOfDatesBetween2Dates.filter(x => !datesFromSchedule.includes(x));
  return disabledDate.map( x => { return stringToDate(x)})
}
function compareDateTime(dateTime){
  var arrayOfStrings = dateTime.split(' ');
  var time=arrayOfStrings[1].split(':');
  arrayOfStrings[0]=arrayOfStrings[0].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  var r = stringToDate(arrayOfStrings[0])
  return new Date() < new Date(r.year,r.month-1,r.day,parseInt(time[0], 10),parseInt(time[1], 10))
}

function convertScheduleFormat(dateTime){
  var arrayOfStrings = dateTime.split(' ');
  var time=arrayOfStrings[1].split(':');
  var hour = parseInt(time[0], 10);
  arrayOfStrings[0]=arrayOfStrings[0].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  arrayOfStrings[1] = `${(hour + 11) % 12 + 1}:${time[1]} ${hour >= 12 ? "PM":"AM"}`
  return arrayOfStrings.join(' ')
}
