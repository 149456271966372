
const convertDOB = (dob,isSubmit=false) => {
    const day=('0'+ dob.getDate().toString()).slice(-2);
    const month= ('0'+ (dob.getMonth()+1).toString()).slice(-2);
    const year=dob.getFullYear().toString();
    return isSubmit ? [year,month,day].join('')
    : [month,day,year].join('/')
}   

const convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
       hours = '00';
    }
    if (modifier === 'PM') {
       hours = parseInt(hours, 10) + 12;
    }
    return `${('0'+hours).slice(-2)}:${minutes}`;
 };

const convertDateTime = dateTime => {
    return dateTime===null ? "" :
    `${dateTime.date.year}-${('0'+(dateTime.date.month)).slice(-2)}-${('0'+dateTime.date.day).slice(-2)} ${convertTime(dateTime.time)}`;
}

export default{
    convertDOB,
    convertDateTime,
    convertTime
}