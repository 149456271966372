import React from 'react';
import './fields.css'
import InputMask from 'react-input-mask';

const InputMaskField=({field,mnemonic,handleChange,mask,formatChars}) => {
    return(        
        <InputMask 
        {...field}
        mask={mask}
        className="fieldBox" 
        formatChars={formatChars}
        onChange={(e) => { handleChange(mnemonic,e.target.value)}}/>
    )
}

export default InputMaskField