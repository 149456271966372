import React from 'react';
import filterById from '../../../../resources/jsonFuncions';
import ConvertFunctions from './ConvertFunctions';
import { useDispatch, useSelector} from 'react-redux';

import RecordType2 from '../../../../resources/TransactionTemplate.json'


export default function DataList(props){
    
    let office = useSelector(state => {
        const { resultRequest } = state;
        //return "https://choicebiometrics.com/schedule/"
        return resultRequest.agency.agency;
    });

    const record = props.record;

    let entries = Object.entries(record.DATA)
    let data = entries.map( ([key, val] = entry) => {
        //console.log(key,val)
        let [name, value] = ['',val];

        RecordType2.template.some((r)=>{
            const field = r.find(f=> f.mnemonic==key);
            if(field){
                name = field.name;
                if(field.type == 'listBox') value = val?.Label
                if(field.type == 'date') value = ConvertFunctions.convertDOB(val)
                return true;
            }
            return false;
        })
        return(
            <Field name={name} value={value}/>
        )        
      });

    return(
        <div>
        <div className="checkPlease">CHECK YOUR DATA, PLEASE</div>
        <div className="dataList">
            <div className="dataList-item row">
                <div className="dataList-name">Type of Transaction:</div>
                <div className="dataList-value uppercase">{filterById(props.dataSources.DS_TOT,'Value',record.T1_TOT).Label}</div>
            </div>
            {record.T2_PFS !== null ? 
                <div className="dataList-item row">
                    <div className="dataList-name">Purpose for Fingerprint Submission:</div>
                    <div className="dataList-value uppercase">
                        {record.T2_PFS.Name}
                    </div>
                </div> : null
            }
            { data }
            { office?.HasScheduler ?
                <div className="dataList-item row">
                    <div className="dataList-name">Date and Time of Meeting:</div>
                    <div className="dataList-value uppercase">{record.SCHEDULE===null ? "" :
                        `${('0'+record.SCHEDULE.date.month).slice(-2)}/${('0'+record.SCHEDULE.date.day).slice(-2)}/${record.SCHEDULE.date.year} ${record.SCHEDULE.time}`}</div>
                </div>
                : null
            } 
        </div>
    </div>
    )
}



const Field = ({name, value}) => {
    return (
        <div className="dataList-item row">
            <div className="dataList-name">{name}:</div>
            <div className="dataList-value uppercase">{value}</div>
        </div>
    )
}
function convertDOB(e){
    const day=e.getDate().toString();
    const month=(e.getMonth()+1).toString();
    const year=e.getFullYear().toString();

    return [month,day,year].join('/');
}   