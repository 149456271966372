import React, { useState,useEffect } from 'react';
import './SubmitStyles.css';
import { useDispatch, useSelector} from 'react-redux';
import requestActions from '../../../../store/requests/requestActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory,useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DataList from './DataList';
import ConvertFunctions from './ConvertFunctions';
import RecordType2 from '../../../../resources/TransactionTemplate.json'

export default function Submit (props) {
    const dispatch = useDispatch();

    const record = useSelector(state => state.record);
    
    const office = useSelector(state => {
        const { resultRequest } = state;
        return resultRequest.agency.agency;
    });
    const errorSubmit = useSelector(state => state.resultRequest.submit.error);    
    const classes = useStyles();
    const [step, setstep] = props.functions;
    const [open, setOpen] = useState(false);
    let history = useHistory();   
    const {search}=useLocation();
    const agency = new URLSearchParams(search).get('agency');

    useEffect(() => {
        if (errorSubmit!==null) setOpen(false)
    }, [errorSubmit])

    function sumbit(){

        if(errorSubmit==="Session has been expired."){
            dispatch(requestActions.resetRequests());
            history.push({pathname: "/",search: search});   
            return;
        }
        else{
            dispatch(requestActions.submitRecord(userData(record),agency===null ? "LSITBIO00" : agency,()=>{
                setstep(step+1);
            }
            ));
            setOpen(true)
        }
    }
    return(
        <div>
            <div className='col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4'>
                <DataList record={record} dataSources={props.dataSources}/>
                <div className='error' hidden={errorSubmit===null}>{errorSubmit}</div>
            </div>
            <div className='buttons'>
                <button className="button button-next" onClick={()=>setstep(step-1)}>Prev</button>
                
                <button className="button button-next submit" onClick={sumbit}>{errorSubmit==="Session has been expired." ? "Return" : "Submit"}</button>
            </div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> 
        </div>
    )
}


const userData = (record) => {

    let data = Object.assign({}, record.DATA);
    
    Object.keys(data).forEach((item) => {
        RecordType2.template.some((r)=>{
            const field = r.find(f => f.mnemonic==item);
            //console.log(item,data[item])
            if(field){
                if(field.type == 'listBox') data[item] = data[item]?.Value || null;
                if(field.type == 'date') data[item] = ConvertFunctions.convertDOB(data[item],true)
                if(field.type == 'ssn') data[item] = data[item].replaceAll('-','');
                if(field.type == 'height') data[item] = data[item].replaceAll('’','');

                return true;
            }
            return false;
        })
    })
    
    return {
        ...data,
        "Reason":record.T2_PFS?.Id,
        "SCHEDULE":ConvertFunctions.convertDateTime(record.SCHEDULE),
        "Contact": localStorage.getItem('contact'),
        "Token": localStorage.getItem('token')
    };
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
