import React, { useEffect, useState,useRef } from 'react';    
import './ModalStyles.css';
import requestActions from '../../store/requests/requestActions';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory,useLocation  } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from './TabPanel';
import InputMask from 'react-input-mask';
import CircularProgress from '@material-ui/core/CircularProgress';
  
export default function Modal (props)
{
    const dispatch = useDispatch();
    const resultRequest = useSelector(state => state.resultRequest)
    
    let history = useHistory(); 
    const { search } = useLocation();
    
    const [typeVerification, setTypeVerification] = useState(0);
    const [contact,setContact]=useState('')
    const [code,setCode]=useState('');
    const [loading, setLoading] = useState(false);
    const [validCode,setValidCode]=useState(true);
    
    const [invalidEmail,setInvalidEmail]=useState(false);
    const [codeDisable, setCodeDisable]=useState(true);
    var codeInput = useRef(null);
    var phoneInput = useRef();
    var emailInput = useRef();
    
    let office = useSelector(state => {
        const { resultRequest } = state;
    
        return resultRequest.agency.agency;
    });


    useEffect(() => {
        if(typeVerification===0) phoneInput.getInputDOMNode().focus();
        else emailInput.current.focus();
    }, [props.active]);

    useEffect(()=>{
        setContact('')
        if(typeVerification===0) phoneInput.getInputDOMNode().focus();
        else emailInput.current.focus();
    }, [typeVerification])

    useEffect(()=>{
        if (resultRequest.checkedCode.error!==null){
            setLoading(false)
        } 
      }, resultRequest.checkedCode.error)
      
    useEffect(() => {
        if(!codeDisable) 
        codeInput.getInputDOMNode().focus();
        
    }, [codeDisable])

    function onChangeContact(e){
        setContact(typeVerification===0 ? e.target.value.replace(/[^\d\+]/g, '')
                : e.target.value)
    }
    function onChangeCode(e){
        const code=e.target.value.replace(/[^\d\+]/g, '');
        setCode(code);
        setValidCode(true)
    }    

    function checkCodeUser() {
        const user = 
            {"Contact": contact,"Code": code};

        if(resultRequest.sendCode.code===hashCode(user.Code)){
            dispatch(requestActions.checkCode(user,(res)=>{
                if(res)
                    history.push({pathname: "/demo",
                                    search: search});
            }))
            setLoading(true);                
        }
        else
            setValidCode(false)
    }
    function sendCodeUser(){
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(typeVerification===1 && !pattern.test(contact)){
            setInvalidEmail(true);
            return;
        }
        setInvalidEmail(false);
        dispatch(requestActions.sendCode(contact,office.Ori));
        setCodeDisable(false);
    }

    function handlePressContact(e){
        if(e.key==="Enter" && (typeVerification===0 ? contact.length===12 : contact!=='')) sendCodeUser();
    }
    
    function handlePressCode(e){
        if(e.key==="Enter" && code.length===4) checkCodeUser();
    }

    const classes=classStyles()

    return(  
        <div className={props.active ? 'modal-v active' : 'modal-v'} onClick={()=>props.setActive(false)}>
            <div className='modal-content' onClick={e=>e.stopPropagation()}>
                <div className="close" onClick={()=>props.setActive(false)}/>

                <p className='modal-header-name'>Security verification</p>
                <p className="modal-description">To secure your account, please complete the following verification.</p>
                        
                {/* <div>
                    <label className="modal-enter-data-label">Enter your email to verify your identity</label>
                    <div className="mb-3">
                        <div className="modal-enter input-group">
                            <input type="email" className="modal-enter-input" ref={emailInput} value={contact} onChange={onChangeContact}  onKeyPress={handlePressContact}/> 
                            <button className="btn modal-send-code" disabled={contact===''} type="button" onClick={sendCodeUser}>{codeDisable ? 'Send code' : "Resend code"}</button>        
                        </div>
                        <label hidden={!invalidEmail} className="error-code" >Invalid email address</label>
                    </div>
                </div> */}
               <AppBar className={classes.appBar} position="static">
                    <Tabs className={classes.tabs} value={typeVerification} TabIndicatorProps={{style: {backgroundColor: "#27689E"}}} onChange={(event, newValue) => {setTypeVerification(newValue)}}>
                        <Tab className={classes.tab} label="Phone" {...a11yProps(1)} />
                        <Tab className={classes.tab} label="Email" {...a11yProps(0)} />
                    </Tabs>
                </AppBar>

                <TabPanel className={classes.tabPanel} value={typeVerification} index={0}>
                    <label className="modal-enter-data-label">Enter your mobile phone number to verify your identity</label>
                    <div className="mb-3">
                        <div className="modal-enter input-group">
                            <InputMask mask="+9 (999) 999-9999" ref={(el)=> {phoneInput = el;}} className="modal-enter-input" value={contact} onChange={onChangeContact} onKeyPress={handlePressContact}/>
                            <button className="btn modal-send-code" disabled={contact.length!==12} type="button" onClick={sendCodeUser}>{codeDisable ? 'Send code' : "Resend code"}</button>        
                        </div>
                    </div> 
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={typeVerification} index={1}>
                    <label className="modal-enter-data-label">Enter your email to verify your identity</label>
                    <div className="mb-3">
                        <div className="modal-enter input-group">
                            <input type="email" className="modal-enter-input" ref={emailInput} value={contact} onChange={onChangeContact}  onKeyPress={handlePressContact}/> 
                            <button className="btn modal-send-code" disabled={contact===''} type="button" onClick={sendCodeUser}>{codeDisable ? 'Send code' : "Resend code"}</button>        
                        </div>
                        <label hidden={!invalidEmail} className="error-code" >Invalid email address</label>
                    </div> 
                </TabPanel>
            
                <label className="modal-enter-data-label" >Enter verification code</label>
                <div className="modal-enter input-group">
                    <InputMask mask="9999" name="Code" className="modal-enter-input code " ref={(el) => { codeInput = el; }} disabled={codeDisable} value={code} onChange={onChangeCode} onKeyPress={handlePressCode}/>
                </div>
                <label hidden={validCode} className="error-code">That code didn’t work. Check the code and try again</label>
                
                <label className="remind" hidden={ codeDisable || typeVerification===0 }>Please ensure to check your spam folder incase you can't find it in your inbox</label>

                <button className="modal-btn-submit" disabled={loading || code.length!==4} onClick={checkCodeUser}>
                    {loading ? <CircularProgress size={25} style={{'color': '#27689E'}}/> : 'Submit'}
                </button>
            </div>
        </div>
    );
};


function hashCode (str){
    var hash = 0;
    var char=0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash;
    }
    return hash;
}

function classStyles(){
    const useStyles = makeStyles(theme => ({
        appBar: {
            boxShadow: "none",
            backgroundColor: "transparent",
            color:'#363636',
            paddingTop:'0px',
            paddingBottom:'0px',
            '@media (min-width:320px)':{
                marginBottom:'5px'                
            }
        },
        tabs:{
            borderBottom: '1px solid #DADADA',
        },
        tab:{
            minWidth:'60px',
            textTransform: 'initial',
            fontSize: '16px',
            lineHeight: '20px'
        },
        tabPanel:{
            '& .MuiBox-root': {
                padding: '5px 0px 0px 0px',
                },
              },
            // padding:'0px'
        //}
      }));

      return useStyles()
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
        };
    }