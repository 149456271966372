import axios from 'axios';

import {SEND_CODE_SUCCESS,
  CHECK_CODE_SUCCESS,
  SUBMIT_RECORD_SUCCESS,
  SEND_CODE_FAILURE,
  CHECK_CODE_FAILURE,
  SUBMIT_RECORD_FAILURE,
  ACTION_RESET_STORE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  GET_AGENCY_SUCCESS,
  GET_AGENCY_FAILURE,
  GET_TRANSACTION_TOKEN_SUCCESS,
  GET_TRANSACTION_TOKEN_FAILURE,
  PAYMENT_SAVE_SUCCESS,
  PAYMENT_SAVE_FAILURE
} from  '../actionTypes';

const instance = axios.create({
    baseURL: 'https://udz24ja2t6.execute-api.us-east-1.amazonaws.com/Prod/v1'
});

const getTimezone = () => {
  return new Date().getTimezoneOffset();
}

const sendCode = (user,agency) => {
    return dispatch => {

      const headers = {
      }
      instance
        .post('/booking/contact', user, {
            headers: headers,
            params: {
              agency: agency,
              timezone: getTimezone()
            }
        })
        .then(res => {
          dispatch(sendCodeSuccess(res.data));
        })
        .catch(err => {
          dispatch(sendCodeFailure(err.response.data));
        });
    };
};

const getAgencyData = (ori,callback) => {
  return dispatch => {
    const headers = {
    };
    
    instance
    .get(`agency/${ori}`, {
          headers: headers,
          timezone: getTimezone()
    })
    .then(res => {
      dispatch(getAgencySuccess(res.data))
      callback(true)
    })
    .catch(err => {
      dispatch(getAgencyFailure(err.response))
      callback(false)
    });
  }
}

const getSchedule = (agency,callback) => {
  return dispatch => {
    const headers = {
    };
    
    const contact = localStorage.getItem('contact')

    instance
    .get(`appointments/${agency}`, {
          headers: headers,
          params:{
            contact: contact ,
            timezone: getTimezone()
          }
      })
      .then(res => {
        dispatch(getScheduleSuccess(res.data));
        callback(res.data)
      })
      .catch(err => {
        dispatch(getScheduleFailure(err.response))
      });
  };
};

const checkCode = ( user,callback ) => {
  return dispatch => {

      const headers = {
        'content-type':'text/plain'
      };

      instance
        .post('/booking/code',user,{
          headers: headers,
          params: {
            timezone: getTimezone()
          }
        })
        .then(res => {
            localStorage.setItem('contact',user.Contact)
            localStorage.setItem('token',res.data)
            dispatch(checkCodeSuccess(true));
            callback(true)
        })
        .catch(err => {
          dispatch(checkCodeFailure(err.response.data));
        });
  };
};

const submitRecord = (user,agency,callback) => {
  return dispatch => {
     const headers = {
      'content-type':'text/plain'
    };

    instance
      .post(`/booking/${agency}`, user, {
            headers: headers,
          params:{
            timezone: getTimezone()   
          }}
        )
        .then(res => {
          dispatch(submitRecordSuccess(res.data));
          callback(true)
        })
        .catch(err => {
          dispatch(submitRecordFailure(err.response?.data));
          
        });
  };
};


const initiateLightBox = (agency,bookingId,callback) => {
  return dispatch => {
    
    const headers = {
      'content-type':'text/plain'
    };
    const params = {
      "Agency": agency,
      "BookingId": bookingId
    };
    
    instance
      .post(`/payment/token`, params, {
          headers: headers}
        )
        .then(res => {
          dispatch(getTransactionTokenSuccess(res.data));
          callback(res)
        })
        .catch(err => {
          dispatch(getTransactionTokenFailure(err));
          
        });
  }
}

const paymentSave = (agency,bookingId,paymentToken,paymentStatus,paymentMessage = "", callback) => {
  return dispatch => {
    //console.log('sfnsfsjk')
    const headers = {
      'content-type':'text/plain'
    };
    const params = {
      "Agency": agency,
      "BookingId": bookingId,
      "PaymentToken": paymentToken,
      "PaymentStatus": paymentStatus,
      "PaymentMessage": paymentMessage
    }
    instance
      .post(`/payment/save`, params, {
          headers: headers,
          params: {
            timezone: getTimezone()
          }}
        )
        .then(res => {
          //console.log(res)
          dispatch(paymentSaveSuccess(res.data));
          callback(res)
        })
        .catch(err => {
          dispatch(paymentSaveFailure(err));
        });
  }
}

const paymentSquareSave = (data,callback) => {
  return dispatch => {
    const headers = {
      'content-type':'text/plain'
    };
    instance
      .post(`/payment/square`, data, {
          headers: headers,
          params: { timezone: getTimezone()}
        }
        )
        .then(res => {
          dispatch(paymentSaveSuccess(res.data));
          callback(res)
        })
        .catch(err => {
          dispatch(paymentSaveFailure(err));
        });
  }
}

const resetRequests=()=>({
  type: ACTION_RESET_STORE
});

const sendCodeSuccess = (res) => ({
  type: SEND_CODE_SUCCESS,
  payload: res
});

const getScheduleSuccess = (res) => ({
  type: GET_SCHEDULE_SUCCESS,
  payload: res
});

const getScheduleFailure = (error) => ({
  type: GET_SCHEDULE_FAILURE,
  payload: error
});

const sendCodeFailure = error => ({
  type: SEND_CODE_FAILURE,
  payload: error
});

const checkCodeSuccess = res => ({
  type: CHECK_CODE_SUCCESS,
  payload: res
});

const checkCodeFailure = error => ({
  type: CHECK_CODE_FAILURE,
  payload: error
});

const submitRecordSuccess = res => ({
  type: SUBMIT_RECORD_SUCCESS,
  payload: res
});

const submitRecordFailure = error => ({
  type: SUBMIT_RECORD_FAILURE,
  payload: error
})

const getAgencySuccess = res => ({
  type: GET_AGENCY_SUCCESS,
  payload: res
});

const getAgencyFailure = error => ({
  type: GET_AGENCY_FAILURE,
  payload: error
})


const getTransactionTokenSuccess = res => ({
  type: GET_TRANSACTION_TOKEN_SUCCESS,
  payload: res
});

const getTransactionTokenFailure = error => ({
  type: GET_TRANSACTION_TOKEN_FAILURE,
  payload: error
})

const paymentSaveSuccess = res => ({
  type: PAYMENT_SAVE_SUCCESS,
  payload: res
});

const paymentSaveFailure = error => ({
  type: PAYMENT_SAVE_FAILURE,
  payload: error
})

export default{
  sendCode,
  getSchedule,
  checkCode,
  submitRecord,
  resetRequests,
  getAgencyData,
  initiateLightBox,
  paymentSave,
  paymentSquareSave
}