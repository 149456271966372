import {ACTION_CHANGE_T1_TOT, ACTION_CHANGE_SCHEDULE,
    ACTION_RESET_STORE, ACTION_CHANGE_T2_PFS,
    ACTION_CHANGE_DATA} from '../actionTypes';

const defaultState={
    T1_TOT: "FAP",
    T2_PFS: null,
    DATA:{},
    SCHEDULE:null,
};

const recordReducer = (state = defaultState, action)=>{
    switch (action.type){
        case ACTION_CHANGE_T1_TOT:
            return { ...state, T1_TOT:action.payload};
            
        case ACTION_CHANGE_T2_PFS:
            return { ...state, T2_PFS:action.payload};
                
        case ACTION_CHANGE_SCHEDULE:
            return { ...state, SCHEDULE:action.payload};
    
        case ACTION_CHANGE_DATA:
            return { ...state, DATA: action.payload };

        case ACTION_RESET_STORE:{
            return defaultState;
        }
        default:{
            return state
        }
    }
};
export default recordReducer;