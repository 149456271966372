import React from 'react';
import './TypesTransactionsStyles.css';
import {useDispatch, useSelector} from 'react-redux';
import recordActions from '../../../../store/record/recordActions';
import TypeTransactionInfo from './TypeTransactionInfo'

export default function TypesTransactions (props) {  
  
  const tots=props.tot;
  const T1_TOT = useSelector(state => state.record.T1_TOT)
  
  let types = useSelector(state => {
    const { resultRequest } = state;
    //console.log(resultRequest.agency)
    return resultRequest.agency.agency?.Types;
  });
  const T2_PFS = useSelector(state => state.record.T2_PFS)

  const [step, setstep] = props.functions;
  const dispatch = useDispatch()

  
  function onSelectedTOT(tot){
    dispatch(recordActions.changeT2_PFS(tot))
    setstep(step+1)
  }

  
  const r = types?.map((tot,index) =>
  <li className='reason' id={index} value={tot.Id} 
    onClick={() => onSelectedTOT(tot)}
    key={tot.Id} tabIndex={index}>
      <span>{tot.Name}</span>
      {T2_PFS?.Id === tot.Id ? <i className="bi bi-check-circle-fill puk"></i> : null}      
  </li>)


  return (
    <div>
      { r!==undefined ?
        <div className='auto-grid'>{r}</div>
      :
      <div className="content col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-3 offset-xl-4">
        <TypesTransactionsList tots={tots} selectedTOT={T1_TOT}/>
        <TypeTransactionInfo selectedTOT={T1_TOT}/>
      </div>
      }
      
      {r===undefined && 
        <div className='buttons'>
            <button className="button button-next" onClick={()=>{setstep(step+1)}}>Next</button>
        </div>
      }
    </div>
  )
}

const TypesTransactionsList = props => {
  const dispatch = useDispatch()
  const tots=props.tots;
    const totList = tots.map((tot,index) =>
      <li className={props.selectedTOT===tot.Value ? "transaction-select" : "transaction"} id={index} value={tot.Value} 
        onClick={()=>dispatch(recordActions.changeT1_TOT(tot.Value))} 
        key={tot.Value} tabIndex={index}>{tot.Label}
      <div className="transaction-select-arrow"  />
      </li>
    )
    return (
      <div className="transactions-list">{totList}</div>
    );
}

