import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import './ProgressStepsStyles.css';

function getSteps(hasScheduler = true) {
    
  return hasScheduler ? ['Select Type of Transaction', 'Demographic Data', 'Select a Date & Time' ,'Submit']
  :  ['Select Type of Transaction', 'Demographic Data','Submit'];
}

export default function ProgressSteps(props) {

    
    let office = useSelector(state => {
        const { resultRequest } = state;
        //return {...resultRequest.agency.agency, HasScheduler: false}
        return resultRequest.agency.agency;
    });



    const activeStep = props.currentStep;
    const stepsName = getSteps(office?.HasScheduler);
    
    const listItems = stepsName.map((stepName,index) =>
        <li key={index} className={index+1<=activeStep ? "progress-step is-active" : "progress-step"}>
            <div className="step-number">{index+1}</div>
            <div className="step-name">{stepName}</div>  
            <svg className='arror' width={index!==stepsName.length-1 ? "50%" : "0"} height="20" viewBox="0 0 120 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-2.18557e-07" y1="9.5" x2="111" y2="9.49999" stroke={index!==stepsName.length-1 ? (index+2<=activeStep ? "#125288" : "#B2B2B2") : ""}  strokeWidth="4"/>
            <path d="M119.5 9.52628L103 19.0526L108 9.52628L103 0L119.5 9.52628Z"fill={index!==stepsName.length-1 ? (index+2<=activeStep ? "#125288" : "#B2B2B2") : ""}/>
            </svg>
        </li>
    );
    
    return (
        <div className="progressStep">
            <div className="progress">{listItems}</div>
            {
                activeStep-1 !== 3 ?
            <div className="progress-name">{getSteps()[activeStep-1]}</div>
            : ""}
        </div>
        

    );
}