export const ACTION_CHANGE_T1_TOT = 'ACTION_CHANGE_T1_TOT';
export const ACTION_CHANGE_T2_PFS = 'ACTION_CHANGE_T2_PFS';

export const ACTION_CHANGE_DATA = 'ACTION_CHANGE_DATA';

export const ACTION_RESET_STORE='ACTION_RESET_STORE';
export const ACTION_CHANGE_SCHEDULE='ACTION_CHANGE_SCHEDULE'

export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS'
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS'
export const SUBMIT_RECORD_SUCCESS = 'SUBMIT_RECORD_SUCCESS'
export const SEND_CODE_FAILURE = 'SEND_CODE_FAILURE'
export const CHECK_CODE_FAILURE = 'CHECK_CODE_FAILURE'
export const SUBMIT_RECORD_FAILURE = 'SUBMIT_RECORD_FAILURE'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAILURE='GET_SCHEDULE_FAILURE'
export const GET_AGENCY_SUCCESS = 'GET_AGENCY_SUCCESS'
export const GET_AGENCY_FAILURE='GET_AGENCY_FAILURE'
export const GET_TRANSACTION_TOKEN_SUCCESS = 'GET_TRANSACTION_TOKEN_SUCCESS'
export const GET_TRANSACTION_TOKEN_FAILURE = 'GET_TRANSACTION_TOKEN_FAILURE'
export const PAYMENT_SAVE_SUCCESS = 'PAYMENT_SAVE_SUCCESS'
export const PAYMENT_SAVE_FAILURE = 'PAYMENT_SAVE_FAILURE'