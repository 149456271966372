import React from 'react';    
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    const useStyles = makeStyles(theme => ({
      box:{
          paddingLeft:'0px',
          paddingRight:'0px',
      }
    }));
  
    const classes = useStyles();
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box className={classes.box} p={2}>
            <Typography  component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};
  