import React from 'react';

import { useHistory,useLocation } from "react-router-dom";
export default function NotFound (){
    let history = useHistory(); 
    const {search}=useLocation()
    function handleClick(){

        history.push('/')    
    }

    return(
         <div>
            <h1>404 - Not Found!</h1>
            <div onClick={handleClick}>Go to iTouchBiometrics</div>
        </div>
    )
}
 