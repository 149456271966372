import React from 'react';
import './fields.css'
import TextField from '@material-ui/core/TextField';
import useStyles from './useStyle';

const TextBoxField = ({field,handleChange,rules,mnemonic}) => {

    const classes = useStyles();
    return(
        <TextField
            {...field}
            classes={classes}
            className="field-box" 
            InputProps={{disableUnderline: true}}
            inputProps={{maxLength:rules.maxLength}}
            type='text'
            onChange={(e) => handleChange(mnemonic,e.target.value.toUpperCase())}
        />
    )    
}

export default TextBoxField;
