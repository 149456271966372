import {ACTION_CHANGE_T1_TOT, ACTION_CHANGE_T2_PFS, 
        ACTION_CHANGE_SCHEDULE, ACTION_RESET_STORE, 
        ACTION_CHANGE_DATA } from '../actionTypes';

const changeT1_TOT = T1_TOT => ({
        type: ACTION_CHANGE_T1_TOT,
        payload: T1_TOT
});
const changeT2_PFS = T2_PFS => ({
        type: ACTION_CHANGE_T2_PFS,
        payload: T2_PFS
});

const changeSCHEDULE = DateTimeMeet => ({
        type: ACTION_CHANGE_SCHEDULE,
        payload: DateTimeMeet
});

const changeDATA = data => ({
        type: ACTION_CHANGE_DATA,
        payload: data
})

const resetStore=()=>({
        type: ACTION_RESET_STORE
});

export default{
        changeT1_TOT,
        changeT2_PFS,
        changeDATA,
        changeSCHEDULE,
        resetStore
}