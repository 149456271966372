import React, { useEffect,useState } from 'react';
import './FinalStyles.css';
import {useDispatch,useSelector} from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import requestActions from '../../store/requests/requestActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {CountdownTimer} from '../../components/CountdownTimer';

export default function Final () {
    //console.log('Final component')
    let history = useHistory();
    const dispatch = useDispatch()
    const office = useSelector(state => {
        const { resultRequest } = state;
        return resultRequest.agency.agency;
    });

    const { search } = useLocation();
    const resultRequest = useSelector(state => state.resultRequest);
      
    const [open,setOpen] = useState(false);  
    const [isLoading,setIsLoading] = useState(false);  
    const [expiration ,setExpiration ] = useState(0);
    const [currentStatus, setCurrentStatus] = useState('');
    const [amount, setAmount]=useState('');
    const [card, setCard] = useState();
    const [idempotencyKey, setIdempotencyKey] = useState();
    const [message, setMessage] = useState('');
    

    useEffect(() => {
        if(office?.PaymentRequired) {
            const HALF_HOUR_IN_MS = Number(office?.PaymentTimeOut) * 60 * 1 * 1000;
            const NOW_IN_MS = new Date().getTime();
            setExpiration(NOW_IN_MS + HALF_HOUR_IN_MS);    
        }
        if(office?.HasPayment && office?.PaymentRequired) setCurrentStatus(status.requiredPayment);
        else if(office?.HasPayment) setCurrentStatus(status.waitingPayment);
        else setCurrentStatus(status.withoutPayment);
    }, [office]); 
        

    function handleClick(){
        history.push({pathname: "/", search: search});
    }

    const error = useSelector(state => {
        const { resultRequest } = state;
        return resultRequest.trans.error;
    });

    const paymentSaveError = useSelector(state => {
        const { resultRequest } = state;
        return resultRequest.paysave.error;
    });

    useEffect(() => {
        setOpen(error!==null)
        setIsLoading(false)
        //setCurrentStatus(status.successPayment)
    },[error]);

    useEffect(() => {
        if(paymentSaveError!==null) setCurrentStatus(status.failedPaymentSave)
        setIsLoading(false)
    },[paymentSaveError]);

    const handleChildClicked = () => {
        setCurrentStatus(status.expiredSession);
    }
    
    function paymentClick(){
        setIsLoading(true);
        dispatch(requestActions.initiateLightBox(office.Id,resultRequest.submit.bookingNum,
        (res) => {  
            const transactionToken = res.data;
            if(office.PaymentType == "square"){
                setCurrentStatus(status.cardDetails)
                setIdempotencyKey(res.data.Token)
                setAmount(res.data.Amount);
                openSquarePayment(transactionToken)
            }
            else{
                openLightBox(transactionToken);
            }
        }))
    }

    function openLightBox(transactionToken){
        var paymentFields = {
            ssl_txn_auth_token: transactionToken
        };
        var callback = {
            onError: function (error) {
                setOpen(true);
                setIsLoading(false);
                setCurrentStatus(status.failedPayment);
            },
            onCancelled: function () {
                setIsLoading(false);
            },
            onDeclined: function (response) {
                setIsLoading(false);
                setCurrentStatus(status.failedPayment);
                let responseMessage = "";
                if(response.errorName || response.errorMessage) responseMessage = `${response.errorName}. ${response.errorMessage}`;
                if(response.ssl_result_message) responseMessage = `${response.ssl_result_message}`;

                setMessage(responseMessage);

                dispatch(requestActions.paymentSave(office.Id,resultRequest.submit.bookingNum,transactionToken,'declined',responseMessage,
                (res) => {  
                    setIsLoading(false);
                    //setCurrentStatus(status.successPayment)
                }))
            },
            onApproval: function (response) {
                dispatch(requestActions.paymentSave(office.Id,resultRequest.submit.bookingNum,transactionToken,'approval',"",
                    (res) => {  
                        setIsLoading(false);
                        setCurrentStatus(status.successPayment)
                    }))
            }
        };
        PayWithConverge.open(paymentFields, callback);   
    }

    async function openSquarePayment(dataJsonString){
        let script = document.createElement('script');

        script.src = dataJsonString.Endpoint;
        script.type = "text/javascript"
        
        document.body.appendChild(script);

        let t = Square.payments(dataJsonString.AccountId, dataJsonString.LocationId);
        
        cardPay(document.getElementById('card-container'), t);

    }

    async function cardPay(fieldEl, t){

        const card = await t.card({
            style: {
              '.input-container.is-focus': {
                borderColor: '#006AFF'
              },
              '.message-text.is-error': {
                color: '#BF0020'
              }
            }
          });
          //console.log('card.attch')
        
          await card.attach(fieldEl);

          setCard(card)
        }

        
    function onRedirect(){
        window.location.href = office?.RedirectTo;
    }

    async function squareBtn(event){
        try {
            const result = await card.tokenize();
            if (result.status === 'OK') {
                const dataJsonString = JSON.stringify({
                    Agency: office.Id,
                    BookingId: resultRequest.submit.bookingNum,
                    SquareToken: result.token,
                    PaymentToken: idempotencyKey
                    });

                dispatch(requestActions.paymentSquareSave(dataJsonString,
                (res) => {
                    //console.log(res)  
                    setIsLoading(false);
                    setCurrentStatus(status.successPayment)
                }))
            }
          } catch (e) {

          }
    }
    const FinalButton = (({currentStatus}) => {
        if(currentStatus==status.cardDetails) {
            return (
                <ButtonByStatus text="Pay now" handleClick={squareBtn} />       
            )
        }
        if(currentStatus===status.successPayment) {
            return (
                <ButtonByStatus text="Return" handleClick={handleClick} />       
            )
        }
        if((currentStatus===status.successPayment || currentStatus===status.withoutPayment) && office?.RedirectTo)
            return (
                <ButtonByStatus text="Continue" handleClick={onRedirect} />
            )
        if(((currentStatus===status.successPayment || currentStatus===status.withoutPayment) && !office?.RedirectTo)
        || currentStatus===status.expiredSession
        || currentStatus===status.failedPaymentSave
        )
            return (
                <ButtonByStatus text="Close" handleClick={handleClick} />
            )
        
        if(currentStatus===status.failedPayment){
            return (
                <ButtonByStatus text="Try again" handleClick={paymentClick} disabled={isLoading} />
            )
        }
        
        if(currentStatus===status.waitingPayment || currentStatus===status.requiredPayment){
            return (
                <ButtonByStatus text="Pay online" handleClick={paymentClick} disabled={isLoading} />
            )
        }
        
        return null
    })

    return(
        <div className="booking">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal:'right' }}
                open={open}
                onClose={()=>setOpen(false)}>
                            
                <Alert variant="filled" onClose={()=>setOpen(false)} severity="error" sx={{ width: '100%' }}>
                Process Failed
                </Alert>
            </Snackbar>

            <div className='header'>
                <h3 style={{fontWeight: '400'}}>LIVESCAN APPOINTMENT</h3>
            </div>

            <div className='col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 card'>
                    
                <div style={{textAlign: 'center', colol:'#5F5E5E'}}>
                    
                    <TitleByStatus currentStatus={currentStatus}/>
                    
                    { currentStatus == status.cardDetails &&
                        <div>
                            <div id="card-container"></div>
                            <span id="payment-flow-message"></span>
                            
                            <p style={{colol:'#5F5E5E', fontSize:'18px', margin: '20px 0', textAlign:'center'}}>
                                Amount: <strong>{amount} &#36;</strong>
                            </p>    
                        </div>
                    }

                    { currentStatus != status.cardDetails && currentStatus != status.expiredSession && 
                        <p style={{colol:'#5F5E5E', fontSize:'18px'}}>
                        Appointment Number: <strong>{resultRequest.submit.bookingNum}</strong>
                        </p>    
                    }

                    { (currentStatus == status.failedPayment && message) &&
                    <div>
                        <h5 style={{colol:'#5F5E5E', margin:'20px 0', marginTop:'40px'}}>
                            {message}
                        </h5>
                    </div>
                    } 
                    { currentStatus == status.failedPaymentSave &&
                    <div>
                        <h5 style={{colol:'#5F5E5E', margin:'20px 0', marginTop:'40px'}}>
                            {paymentSaveError}
                        </h5>
                    </div>
                    } 


                    { (currentStatus == status.requiredPayment || currentStatus == status.cardDetails) &&
                    <div>
                        <p style={{colol:'#5F5E5E', margin:'20px 0', marginTop:'40px'}}>
                            You now have {office?.PaymentTimeOut} minutes to pay for the service.<br/>
                            The appointment will be discarded if not paid within the next {office?.PaymentTimeOut} minutes.
                        </p>
                        <CountdownTimer targetDate={expiration} handle={handleChildClicked}/> 
                    </div>
                    } 

                    { currentStatus == status.expiredSession &&
                    <div>
                        <h5 style={{colol:'#5F5E5E', margin:'20px 0', marginTop:'40px', fontWeight: '400'}}>
                            Your appointment has been cancelled. If you want to schedule an appointment, please try again.
                        </h5>
                        <CountdownTimer targetDate={expiration} handle={handleChildClicked}/> 
                    </div>
                    } 

                    { (currentStatus == status.requiredPayment ||
                    currentStatus == status.waitingPayment) &&
                        <p style={{colol:'#5F5E5E', margin:'15px 0'}}>
                            Don't close this window before the payment proceeds
                        </p>
                    }
                    
                    <FinalButton currentStatus={currentStatus}/>                    

                </div>
            </div>

        </div>
    )
}

const status = {
    successPayment: 'successPayment',
    failedPayment: 'failedPayment',
    cardDetails: 'cardDetails', 
    requiredPayment: 'requiredPayment', 
    waitingPayment: 'waitingPayment',
    withoutPayment: 'withoutPayment', 
    expiredSession: 'expiredSession',
    failedPaymentSave: 'failedPaymentSave'
}


const TitleByStatus = (({currentStatus}) => {
    const Image = (({currentStatus}) => {
        return (
            currentStatus === status.expiredSession && <img src="./expiration.svg" height="80px"/> ||
            currentStatus === status.successPayment && <img src="./paymentSuccessful.svg" height="80px"/> ||
            (currentStatus === status.failedPayment  || currentStatus == status.failedPaymentSave) && <img src="./paymentFailed.svg" height="80px"/> ||
            (currentStatus == status.requiredPayment || currentStatus == status.waitingPayment) && <img src="./submittedPayment.svg" height="70px"/> ||
            currentStatus === status.withoutPayment && <img src="./submitted.svg" height="80px"/> || null
        )
    })
       
    const Title = (({currentStatus}) => {
        return (
            currentStatus === status.cardDetails && <h4 style={{margin:'20px 0'}}>Card Details</h4> ||
            currentStatus === status.expiredSession && <h4 style={{margin:'20px 0', marginTop:'40px'}}>Your session has expired</h4> ||
            currentStatus === status.successPayment && <h4 style={{margin:'20px 0', color:'#4ADA7F'}}>PAYMENT SUCCESSFUL</h4> ||
            currentStatus === status.failedPayment && <h4 style={{margin:'20px 0', color:'#DA4A4A'}}>PAYMENT FAILED</h4> ||
            currentStatus === status.failedPaymentSave && <h4 style={{margin:'20px 0', color:'#DA4A4A'}}>PROCESS FAILED</h4> ||
            (currentStatus === status.requiredPayment || currentStatus === status.waitingPayment || currentStatus === status.withoutPayment) 
                && <h4 style={{margin:'20px 0', marginTop:'40px'}}>Your Application has been submitted</h4>
             || null
        )  
    })

    return (<div>
        <Image currentStatus={currentStatus}></Image>
        <Title currentStatus={currentStatus}></Title>
    </div>)
})




const ButtonByStatus = (({text, handleClick, disabled = false}) => {
    return(
        <button disabled={disabled} onClick={handleClick} className="button-close">{text}</button>
    )
}) 