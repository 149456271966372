import React, { useState, useEffect,  } from 'react';

import { useCountdown } from '../hooks/useCountdown';
import './CountdownTimerStyle.css'

export const CountdownTimer = ({ handle, targetDate }) => {
  
  const [minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if(minutes + seconds == 0){
      handle();
    }
  }, [minutes, seconds])

  if (minutes + seconds <= 0) {
    return <div/>;
  } else {
    return (
      <ShowCounter
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};


const ShowCounter = ({ minutes, seconds }) => {
  return (
    <div className="show-counter">
        <p>
          {minutes.toString().padStart(2, '0')}
          :{seconds.toString().padStart(2, '0')}
        </p>

    </div>
  );
};

// export default function CountDownTimer (props) {

//     const hoursMinSecs = {hours:1, minutes: 20, seconds: 40}

//     const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
//     const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);
    

//     const tick = () => {
   
//         if (hrs === 0 && mins === 0 && secs === 0) 
//             reset()
//         else if (mins === 0 && secs === 0) {
//             setTime([hrs - 1, 59, 59]);
//         } else if (secs === 0) {
//             setTime([hrs, mins - 1, 59]);
//         } else {
//             setTime([hrs, mins, secs - 1]);
//         }
//     };


//     const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    
//     useEffect(() => {
//         const timerId = setInterval(() => tick(), 1000);
//         return () => clearInterval(timerId);
//     });

    
//     return (
//         <div>
//             <p>{`${hrs.toString().padStart(2, '0')}
//             :${mins.toString().padStart(2, '0')}
//             :${secs.toString().padStart(2, '0')}`}</p> 
//         </div>
//     );
// }