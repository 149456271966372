import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './DemographicDataStyles.css';
import { useForm } from 'react-hook-form';
import './components/fields.css';
import Field from './components/Field';
import RecordType2 from '../../../../resources/TransactionTemplate.json'
import recordActions from '../../../../store/record/recordActions';

export default function DemographicData (props) {  

    const { control, handleSubmit, setValue, formState: { errors }} = useForm();
    const [step, setstep] = props.functions;

    const [template, setTemplate] = useState(null);
    const [office,data,reason] = useSelector(state => {
        const { resultRequest } = state;
        return [resultRequest.agency.agency, state.record.DATA, state.record.T2_PFS];
    });


    useEffect(() => {
        let card = RecordType2.template.map(r=>
            r.map(f => {
                if(reason && reason?.Id?.includes('PERC') && f.mnemonic=='T2_SOC'){
                    return {...f,...{minOccurs:"1"}}
                }
                if(office && office?.HasAddress && f.mnemonic.includes('T2_RES')){
                    return {...f,...{minOccurs:"1",commonField:true}}
                }
                if(office && office?.AdditionalFields?.includes(f.mnemonic)){
                    return {...f,...{commonField:true}}
                }
                if(office && office?.ExcludeFields?.includes(f.mnemonic)){
                    return {...f,...{minOccurs:"0",commonField:false}}
                }
                return f;
            }).filter(f => f.commonField)
        ).filter(({ length }) => length);
    
        setTemplate(card);
    
        card.forEach(row => {
            row.forEach(field => {
            const value = data[field.mnemonic] || ((field.type=='listBox' || field.type=='date') ? null : '');
            setValue(field.mnemonic,value);
        })
        });
    },[office]);

    function handleChange(mnemonic, value) {
        setValue(mnemonic, value);
        data[mnemonic] = value;
        dispatch(recordActions.changeDATA(data))
    }


    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(recordActions.changeDATA(data))
        setstep(step+1);
      };


    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate 
        className="col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
        {
            template?.map((row,id) => {
                return (
                    <div key={id} className="row">
                    {
                        row.map((item)=>{
                            return (
                                <div key={item.mnemonic} className={getClassName(row.length,item)}>
                                    <Field 
                                    item={item} 
                                    control={control}
                                    handleChange={handleChange} 
                                    errors={errors}
                                    />
                                </div>
                            )
                        })
                    }
                    </div>
                )
            })
        }

        <div className='buttons'>
            <button type="button" className="button button-next" onClick={()=>setstep(step-1)}>Prev</button>
            <button type="submit" className="button button-next">Next</button>
        </div>
        </form>
    )
}

const getClassName = (col, item) => {
    if(item.type=="height" || item.type=="weight") return "subfield col-xs-3 col-sm-3 col-md-2 col-lg-2"
    if(col==4) return "subfiled col-xs-6 col-sm-6 col-md-4 col-lg-4"
    if(col==2) return "subfiled col-xs-6 col-sm-6 col-md-6 col-lg-6"
    if(col==3) return "subfield col-xs-6 col-sm-6 col-md-4 col-lg-4"
    
    return ""
}
