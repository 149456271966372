import {SEND_CODE_SUCCESS,
     CHECK_CODE_SUCCESS,
     SUBMIT_RECORD_SUCCESS,
     SEND_CODE_FAILURE,
     CHECK_CODE_FAILURE,
     SUBMIT_RECORD_FAILURE,
     ACTION_RESET_STORE,
     GET_SCHEDULE_SUCCESS,
     GET_SCHEDULE_FAILURE,
     GET_AGENCY_SUCCESS,
     GET_AGENCY_FAILURE,
     GET_TRANSACTION_TOKEN_SUCCESS,
     GET_TRANSACTION_TOKEN_FAILURE,
     PAYMENT_SAVE_SUCCESS,
     PAYMENT_SAVE_FAILURE} from  '../actionTypes';

//some bullshit, for each request to do reducer?
const initialState = {
    sendCode:{
        code:'',
        error:null,
    },
    submit: {
        bookingNum:'',
        error:null
    },
    checkedCode: {
        checkedCode: false,
        error:null
    },
    schedule: {
        schedule:[],
        error:null
    },
    agency: {
        agency:null,
        error:null
    },
    trans: {
        data: null,
        error: null
    },
    paysave: {
        data: null,
        error: null
    }
  };

const requestReducer = (state=initialState, action) => {
    switch (action.type){
        case SEND_CODE_SUCCESS:
            return { ...state, sendCode:{ code:action.payload,  error:null} };
        case CHECK_CODE_SUCCESS:
            return { ...state, checkedCode:{checkedCode:action.payload, error:null} };
        case SUBMIT_RECORD_SUCCESS:
            return { ...state, submit: {bookingNum:action.payload, error:null} };
        
        case SEND_CODE_FAILURE:
            return { ...state, sendCode:{ code:'',error:action.payload} };
        case SUBMIT_RECORD_FAILURE:{
            return { ...state, submit:{bookingNum:'', error:action.payload} };
        }
        case CHECK_CODE_FAILURE:
            return { ...state, checkedCode:{ checkedCode:false, error:action.payload} };
        case GET_SCHEDULE_SUCCESS:
            return { ...state, schedule:{schedule:action.payload, error:null}}
        case GET_SCHEDULE_FAILURE:
            return { ...state, schedule:{ schedule:null, error:action.payload} };
        case GET_AGENCY_SUCCESS:
            return { ...state, agency:{agency:action.payload, error:null}}
        case GET_AGENCY_FAILURE:
            return { ...state, agency:{agency:null, error:action.payload} };
        
        case GET_TRANSACTION_TOKEN_SUCCESS:
            return { ...state, trans:{data:action.payload, error:null}}
        case GET_TRANSACTION_TOKEN_FAILURE:
            return { ...state, trans:{data:null, error:action.payload} };

        case PAYMENT_SAVE_SUCCESS:
            return { ...state, paysave:{data:action.payload, error:null}}
        case PAYMENT_SAVE_FAILURE:
            return { ...state, paysave:{data:null, error:action.payload} };
    
        case ACTION_RESET_STORE:{
            return initialState;
        }
        default:
            return state 
    }
}

export default requestReducer;